<template>
  <div>
    <div class="container2">
      <section class="main">
        <div class="loading" v-if="loading == 1" @click="loading = 0">
          <div class="tank">
            <a-spin style="margin-top: 20px" />
            <p>正在获取中</p>
          </div>
        </div>

        <div class="no-result" v-if="list.length == 0 && loading == 2">
          <a-icon type="info-circle" />
          <span>您还没有收藏文书</span>
        </div>

        <div class="list" id="l" style="padding: 10px 15px">
          <div v-for="(item, index) in list" :key="index" class="list-item">
            <div class="t">
              <div class="index">{{ index + 1 }}</div>
              <div class="word-num">正文字数：{{ item.content.qw.length }} 字</div>
            </div>
            <router-link class="title" :to="'/detail?id=' + item.wid" target="_blank">
              <span class="b" v-html="item.content.s1"></span>
            </router-link>
            <div class="sub-title">
              <span>{{ item.content.s2 }}</span>
              <span>{{ item.content.s7 }}</span>
              <span>{{ item.content.s31 }}</span>
              <span>{{ item.content.prov }}</span>
            </div>
            <div class="info">
              <span style="margin-right: 20px; color: #555">
                <b>审判程序：</b>{{ item.content.s9 }}
              </span>
              <span style="color: #555"> <b>案由：</b>{{ item.content.s11 }} </span>
            </div>

            <div class="info" v-if="item.content.s45"><b>关键词：</b>{{ item.content.s45 }}</div>
          </div>
        </div>

        <div class="pagination" v-if="loading > 0 && list.length > 0">
          <a-pagination v-model="page" show-quick-jumper :default-current="1" :total="total" :pageSize="30"
            @change="search" />
        </div>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  data() {
    return {
      list: [], //检索结果
      loading: 0,
      total: 0, //结果总数量
      page: 1, //当前页
    }
  },
  mounted() {
    this.search();
  },
  methods: {
    async search() {
      this.loading = 1;
      const r = await this.$axios.get('/getFavList?page=' + this.page);
      this.loading = 2;
      console.log(r, 999922136729);

      for (let item of r.list) {
        item.content.s45 = item.content.s45.replaceAll(',', '、')
      }

      if (r.status == 1) {
        this.list = r.list || []
        this.total = r.total || 0;
      }
    },

    async unFav (wid, index) {
      const r = await this.$axios.post('/fav', {
        wid: wid,
        status: 0
      })

      if (r.status == 1) this.search();
    }
  }
};
</script>