<template>
  <div style="margin-top: 20px">
    <div class="item">
      <a-input size="large" v-model="form.tel" placeholder="请输入用户账号">
        <a-icon slot="prefix" type="user" />
      </a-input>
    </div>
    <div class="item">
      <a-input-password size="large" v-model="form.password" placeholder="请输入密码">
      </a-input-password>
    </div>
    <div class="item">
      <a-checkbox v-model="form.agree">
        我已阅读并同意<span class="link">《使用条款》</span>
      </a-checkbox>
    </div>
    <div class="item">
      <a-button size="large" style="width: 100%" type="primary" @click="login">提交登录</a-button>
    </div>
    <div class="item">
      <a-button size="large" type="link" @click="$router.push('/')">返回首页</a-button>
      <a-button size="large" style="float: right" type="link" @click="$router.push('/reg')">注册账号</a-button>
    </div>
  </div>
</template>

<style lang='scss' scord>
</style>

<script>
export default {
  data() {
    return {
      form: {
        tel: '',
        password: '',
        agree: false
      }
    }
  },
  methods: {
    async login() {
      console.log(this.form);
      if (!this.form.tel) return this.$message.error('请输入用户账号')
      if (!this.form.password) return this.$message.error('请输入登录密码')
      if (!this.form.agree) return this.$message.error('请阅读并勾选同意使用条款')

      const r = await this.$axios.post('/login', this.form)
      console.log(r);
      if (r.status == 1) {
        localStorage.setItem('userInfo', JSON.stringify(r.userInfo))
        this.$store.commit('setUser', r.userInfo);
        this.$router.push('/')
      }
    },
  }
}
</script>