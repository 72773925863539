<template>
  <div style="text-align: center">
    <div id="qr"></div>
    <a-button size="large" type="link" @click="$router.push('/')">返回首页</a-button>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  mounted() {
    this.showWxLogin();
  },
  methods: {
    async getState() {
      const r = await this.$axios.get("/getLoginState");
      return r.state;
    },
    async showWxLogin() {
      const state = await this.getState()

      // 微信登录js代码
      !(function (a, b, c) {
        function d(a) {
          var c = "default";
          a.self_redirect === !0 ?
            (c = "true") :
            a.self_redirect === !1 && (c = "false");
          var d = b.createElement("iframe"),
            e =
              "https://open.weixin.qq.com/connect/qrconnect?appid=" +
              a.appid +
              "&scope=" +
              a.scope +
              "&redirect_uri=" +
              a.redirect_uri +
              "&state=" +
              a.state +
              "&login_type=jssdk&self_redirect=" +
              c +
              "&styletype=" +
              (a.styletype || "") +
              "&sizetype=" +
              (a.sizetype || "") +
              "&bgcolor=" +
              (a.bgcolor || "") +
              "&rst=" +
              (a.rst || "");
          (e += a.style ? "&style=" + a.style : ""),
            (e += a.href ? "&href=" + a.href : ""),
            (d.src = e),
            (d.frameBorder = "0"),
            (d.allowTransparency = "true"),
            (d.scrolling = "no"),
            (d.width = "300px"),
            (d.height = "400px");
          var f = b.getElementById(a.id);
          (f.innerHTML = ""), f.appendChild(d);
        }
        a.WxLogin = d;
      })(window, document);

      new WxLogin({
        self_redirect: false,
        id: "qr",
        appid: "wx95430383b4b42f11",
        scope: "snsapi_login",
        redirect_uri: "https%3a%2f%2fwenshu.ailvsifa.com%2fwechatLogin",
        state: state,
        style: "black",
        href: "",
      });
    },
  }
}
</script>