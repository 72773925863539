<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {
      code: null,
      state: null,
    };
  },

  async created() {
    this.code = this.$route.query.code;
    this.state = this.$route.query.state;

    const r = await this.$axios.post("/wechatLogin", {
      code: this.code,
      state: this.state,
    });
    console.log(r, 2222);

    if (r.status == 1) {
      localStorage.setItem('userInfo', JSON.stringify(r.userInfo))
      this.$store.commit('setUser', r.userInfo);
      this.$router.push('/')
    }
    else{
      this.$router.push("/login");
    }
  },
};
</script>