<template>
  <header>
    <div class="container">
      <img class="logo" src="../static/logo.png" @click="$router.push('/')" alt="" />
      <div class="title">爱律思法裁判文书检索系统</div>

      <div class="user-zone">
        <template v-if="$store.state.token">
          <router-link to="/vip">
            <img v-if="$store.state.vip == 1" style="height: 20px; margin-top: -2px; margin-right: 10px"
              src="../static/vip.png" alt="">
            <!-- <router-link to="/vip">
            <a-button size="small" style="margin-right: 20px">成为会员</a-button>
          </router-link> -->
            <span class="name">{{ $store.state.name }}</span>
          </router-link>
          <router-link class="link" to="/vip">会员中心</router-link>
          <router-link class="link" to="/favList">收藏夹</router-link>
          <router-link class="link" to="/help">使用说明</router-link>
          <a-button size="small" @click="logout">退出</a-button>
        </template>
        <template v-else>
          <span class="name"></span>
          <router-link to="/login">
            <a-button size="small">登录</a-button>
          </router-link>
          <router-link to="/reg">
            <a-button size="small" style="margin-left: 20px">注册</a-button>
          </router-link>

          <router-link style="margin-left: 20px" class="link" to="/help">使用说明</router-link>
        </template>
      </div>

      <ul class="type-list">
        <li @click="goHome" :class="{ active: $store.state.activeType == null }">首页</li>
        <template v-if="show">
          <li v-for="(type, t) in typeList" :key="t" @click="setType(type)"
            :class="{ active: $store.state.activeType == type }">
            {{ type }}
          </li>
        </template>
      </ul>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      typeList: [
        "民事案件",
        "刑事案件",
        "行政案件",
        "执行案件",
        "管辖案件",
        "指导性案例",
        "国家赔偿与司法救助案件",
        "其他案件"
      ],
      active: null
    };
  },
  computed: {
    show() {
      const route = this.$route.path;
      return !['/login', '/reg', '/favList', '/help'].includes(route)
    }
  },
  methods: {
    goHome() {
      this.$router.push('/')
      this.$store.commit('setType', null)
    },
    setType(type) {
      this.$store.commit('setType', type)
      this.$router.push('/list')
    },
    logout() {
      this.$store.commit('setUser', {
        name: '',
        token: ''
      })
      localStorage.clear()
      this.$router.push('/')
    }
  },
};
</script>