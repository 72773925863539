<template>
  <div id="app">
    <Header />
    <main>
      <a-config-provider :locale="locale">
        <router-view></router-view>
      </a-config-provider>
    </main>   
    <Footer />
  </div>
</template>

<script>
import Header from "./components/header.vue";
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";

import Footer from "./components/footer.vue";
export default {
  components: { Header, Footer },
  data() {
    return {
      locale: zh_CN,
    };
  },
  created() {
    // if (this.$route.query && this.$route.query.token) {
    //   const token = this.$route.query.token
    //   console.log('query传来了token', token);
    //   localStorage.setItem("token", token);
    // } else {
    //   // 用户信息
    //   let token = localStorage.getItem("token");
    //   console.log('本地存储的token', token);
    //   if (!token) {
    //     this.$router.push('/login')
    //   }
    // }
    this.getUserInfo()
  },
  methods: {
    async getUserInfo () {
      const r = await this.$axios.get('/getUserInfo')

      if(r.status == 1){
        this.$store.commit('setUser', r.user)
      }
    }
  }
};
</script>
