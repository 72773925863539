import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    vip: 0,
    name: '', 
    token: '',
    activeType: null,
    loading: 0, // 0未开始检索 1正在检索 2检索完成
    list: [], // 结果
  },
  mutations: {
    setUser (state, user) {
      state.vip = user.vip || 0;
      state.name = user.name;
      state.token = user.token;
    },
    setType (state, type) {
      state.activeType = type
    },
    valTest (state, val) {
      state.test = val
    },
    saveList (state, list) {
      state.list = list
    },
    setLoading (state, status) {
      state.loading = status
    }
  }
})
