<template>
  <div class="index">
    <div class="search" style="margin: 120px auto">
    <a-select class="select" v-model="keywords_type">
      <a-select-option :value="1">标题</a-select-option>
      <a-select-option :value="2">全文</a-select-option>
    </a-select>
      <a-input class="main-input" type="text" v-model="keywords" placeholder="请输入案由、关键词、法院、当事人、律师等信息检索"
        @pressEnter="search">
        <a-icon slot="suffix" type="enter" style="color: #ccc; font-size: 20px" />
      </a-input>
      <button @click="search">检索</button>
    </div>

    <img src="../static/bottom.png" style="width: 100%" alt="">

    <!-- <div class="stat">
      <div class="row1">
        <div class="item">
          <img src="../static/xinzeng.png" alt="">
          <div class="right">
            <div class="label">今日新增</div>
            <div class="num">60623<span>篇</span></div>
          </div>
        </div>
        <div class="item">
          <img src="../static/wenshu.png" alt="">
          <div class="right">
            <div class="label">文书总量</div>
            <div class="num">125776389<span>篇</span></div>
          </div>
        </div>
        <div class="item">
          <img src="../static/fangwen.png" alt="">
          <div class="right">
            <div class="label">访问总量</div>
            <div class="num">60623<span>次</span></div>
          </div>
        </div>

      </div>
      <div class="row2">
        <div class="item">
          <div><div class="i"></div> 民事文书 82,320,870篇</div>
          <div><div class="i"></div> 赔偿文书 143,239篇</div>
        </div>
        <div class="item">
          <div><div class="i"></div> 刑事文书 9,977,157篇</div>
          <div><div class="i"></div> 刑事文书 9,977,157篇</div>
        </div>
        <div class="item">
          <div><div class="i"></div> 刑事文书 9,977,157篇</div>
          <div><div class="i"></div> 其他文书 927,584篇</div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.index{
  background: #fff;
}
</style>

<script>
export default {
  data() {
    return {
      keywords_type: 1,
      keywords: '', //输入的关键词
    }
  },
  created() {
    const query = this.$route.query;
    if (query.token && query.name) {
      const userInfo = {
        token: query.token,
        name: query.name
      }
      localStorage.setItem('userInfo', JSON.stringify(userInfo))
      this.$store.commit('setUser', userInfo);
    }
  },
  methods: {
    search() {
      this.$router.push('/list?keywords=' + this.keywords + '&keywords_type=' + this.keywords_type)
    }
  },
};
</script>