<template>
  <div class="login-bg">
    <div class="login">
      <a-tabs v-model="type">
        <a-tab-pane :key="1" tab="微信登录"></a-tab-pane>
        <a-tab-pane :key="2" tab="账号登录"></a-tab-pane>
        <a-tab-pane :key="3" tab="验证码登录"></a-tab-pane>
      </a-tabs>

      <!-- 微信登录 -->
      <Wechat v-if="type == 1" />
      <!-- 账号密码 -->
      <Account v-if="type == 2" />
      <!-- 短信验证码 -->
      <Code v-if="type == 3" />
    </div>

    <img src="../static/bottom.png" class="bottom" alt="">
  </div>
</template>

<style lang="scss" scoped>
.login-bg{
  background: #fff;
  .bottom{
    width: 100%;
    margin-top: -120px;
    z-index: -100 !important;
  }
}
#qr {
  text-align: center;
}
</style>

<script>
import Wechat from '../components/login/wechat.vue'
import Account from '../components/login/account.vue'
import Code from '../components/login/code.vue'

export default {
  components: { Wechat, Account, Code },
  data() {
    return {
      type: 1,

    }
  },
  mounted() {

  },
  methods: {



  }
}
</script>