<template>
  <div class="detail" v-if="load">
    <ul class="menu-list">
      <li v-for="(menu, m) in menuList" :key="m">
        <a @click="active_menu = menu.title" :href="'#' + menu.id" :class="{ active: active_menu == menu.title }">{{
            menu.title
        }}</a>
      </li>
    </ul>

    <section class="content" id="title">
      <div class="title">{{ detail.s1 }}</div>
      <div id="info">
        <div>
          <strong>审理法院</strong><span>{{ detail.s2 }}</span>
        </div>
        <div>
          <strong>文书类型</strong><span>{{ detail.s6 }}</span>
        </div>
        <div>
          <strong>案号</strong><span>{{ detail.s7 }}</span>
        </div>
        <div>
          <strong>案由</strong><span>{{ detail.s11 }}</span>
        </div>
        <div>
          <strong>案件类型</strong><span>{{ detail.s8 }}</span>
        </div>
        <div>
          <strong>审判程序</strong><span>{{ detail.s9 }}{{ detail.s10 }}</span>
        </div>

        <div>
          <strong>当事人</strong><span>{{ detail.s17 }}</span>
        </div>
        <div>
          <strong>裁判日期</strong><span>{{ detail.s31 }}</span>
        </div>
        <div>
          <strong>公开类型</strong>
          <span v-if="detail.s43 == '01'">文书公开</span>
          <span v-else-if="detail.s43 == '02'">信息公开</span>
          <span v-else>/</span>
        </div>
        <div>
          <strong>关键字</strong><span>{{ detail.s45 || '/' }}</span>
        </div>
      </div>

      <div class="sec" id="yaodian" v-if="detail.yaodian">
        <div class="sec-title"><img src="../static/guandian.png" alt=""><span>裁判要点</span></div>
        <div v-html="detail.yaodian"></div>
      </div>

      <div v-if="detail.dangshiren.length" class="sec" id="dangshiren">
        <div class="sec-title"><img src="../static/dangshiren.png" alt=""><span>当事人</span></div>
        <div>
          <p v-for="(item, index) in detail.dangshiren" :key="index">{{ item }}</p>
        </div>
      </div>

      <div class="sec" id="s23" v-if="detail.s23">
        <div class="sec-title"><img src="../static/jilu.png" alt=""><span>诉讼记录</span></div>
        <div v-html="detail.s23"></div>
      </div>
      <div class="sec" id="s25" v-if="detail.s25">
        <div class="sec-title"><img src="../static/guocheng.png" alt=""><span>审理过程</span></div>
        <div v-html="detail.s25"></div>
      </div>
      <div class="sec" id="s26" v-if="detail.s26">
        <div class="sec-title"><img src="../static/guandian.png" alt=""><span>法庭观点</span></div>
        <div v-html="detail.s26"></div>
      </div>
      <div class="sec" id="s27" v-if="detail.s27">
        <div class="sec-title"><img src="../static/jieguo.png" alt=""><span>判决结果</span></div>
        <div v-html="detail.s27"></div>
      </div>
      <div class="sec" id="s47" v-if="detail.s47.length">
        <div class="sec-title"><img src="../static/yiju.png" alt=""><span>法律依据</span></div>
        <div>
          <div v-for="(item, index) in detail.s47" :key="index">
            <a style="margin-left: 10px; text-decoration: underline" target="_blank"
              :href="'https://www.baidu.com/s?ie=UTF-8&wd=' + item">
              {{ item }}
            </a>
          </div>
        </div>
      </div>
      <div class="sec" id="s28" v-if="detail.s28">
        <div class="sec-title"><img src="../static/shenpan.png" alt=""><span>审判人员</span></div>
        <div v-html="detail.s28"></div>
      </div>
    </section>

    <!-- <section class="content" id="qw" style="margin-top: 40px" v-if="detail.qw">
      <div class="sec" style="margin: 0">
        <div class="sec-title"><img src="../static/quanwen.png" alt=""><span>文书全文</span></div>
        <div v-html="detail.qw"></div>
      </div>
    </section> -->

    <div class="fav-list">
      <div>
        <a-tooltip placement="left">
          <template slot="title">
            打印文书
          </template>
          <a-button icon="printer" shape="circle" type="primary" @click="print"></a-button>
        </a-tooltip>
      </div>
      <div style="margin-top: 20px">
        <a-tooltip placement="left">
          <template slot="title">
            下载文书
          </template>
          <a-button icon="file-word" shape="circle" type="primary" @click="download"></a-button>
        </a-tooltip>
      </div>

      <div style="margin-top: 20px" v-if="favStatus == 1">
        <a-tooltip placement="left">
          <template slot="title"> 取消收藏 </template>
          <a-button icon="heart" shape="circle" type="primary" @click="fav(0)"></a-button>
        </a-tooltip>
      </div>
      <div style="margin-top: 20px" v-else>
        <a-tooltip placement="left">
          <template slot="title"> 收藏文书 </template>
          <a-button icon="heart" shape="circle" type="default" @click="fav(1)"></a-button>
        </a-tooltip>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
em{
  color: #146AC4;
  font-style: normal;
}
@media print {

  header,
  .menu-list,
  .fav-list {
    display: none !important;
  }

  .container {
    max-width: 100% !important;
  }

  .content {
    width: 100% !important;
    border: none !important;
    border-radius: 0 !important;
    margin: 0 !important;
    padding: 40px 0 !important;

    .sec-title {
      display: inline-block;
      width: auto !important;
      background: #fff !important;
      color: #000 !important;
      padding: 0 !important;
      position: relative;
      left: 0 !important;
      text-align: left !important;
      margin-bottom: 15px;
    }
  }
}
</style>

<script>
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";

export default {
  data() {
    return {
      load: false,
      id: null,
      menuList: [
        { id: "title", title: "基本信息" },
        // { id: "qw", title: "文书全文" },
      ],
      active_menu: "基本信息",
      detail: {},
      detail2: {},
      favStatus: 0
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetail();
  },
  methods: {
    async getDetail() {
      const r = await this.$axios.get("/detail?id=" + this.id);

      if (r.status == 1) {
        // 显示左侧菜单
        const detail = r.data.detail;
        if (detail.yaodian) this.menuList.push({ id: "yaodian", title: "裁判要点" });
        if (detail.dangshiren.length) this.menuList.push({ id: "dangshiren", title: "当事人" });
        if (detail.s23) this.menuList.push({ id: "s23", title: "诉讼记录" });
        if (detail.s25) this.menuList.push({ id: "s25", title: "审理过程" });
        if (detail.s26) this.menuList.push({ id: "s26", title: "法庭观点" });
        if (detail.s27) this.menuList.push({ id: "s27", title: "判决结果" });
        if (detail.s47.length) this.menuList.push({ id: "s47", title: "法律依据" });
        if (detail.s28) this.menuList.push({ id: "s28", title: "审判人员" });

        const s6Map = new Map([
          ["01", "判决书"],
          ["02", "裁定书"],
          ["03", "调解书"],
          ["04", "决定书"],
          ["05", "通知书"],
          ["09", "令"],
          ["10", "其他"],
        ]);
        if (r.data.detail.s6) r.data.detail.s6 = s6Map.get(r.data.detail.s6);
        if (r.data.detail.s17) r.data.detail.s17 = r.data.detail.s17.replaceAll(',', '、');
        r.data.detail.dangshiren = r.data.detail.dangshiren.split(',');
        if (r.data.detail.s47)
          r.data.detail.s47 = r.data.detail.s47.split(",");

        this.detail2 = { ...r.data.detail };

        if (r.data.detail.s22) r.data.detail.s22 = this.p(r.data.detail.s22);
        if (r.data.detail.s23) r.data.detail.s23 = this.p(r.data.detail.s23);
        if (r.data.detail.s25) r.data.detail.s25 = this.p(r.data.detail.s25);
        if (r.data.detail.s26) r.data.detail.s26 = this.p(r.data.detail.s26);
        if (r.data.detail.s27) r.data.detail.s27 = this.p(r.data.detail.s27);
        if (r.data.detail.s28) r.data.detail.s28 = this.p(r.data.detail.s28);
        if (r.data.detail.s45) r.data.detail.s45 = r.data.detail.s45.replaceAll(',', '、');

        if (r.data.detail.qw) r.data.detail.qw = this.p(r.data.detail.qw);

        // if (r.data.detail.s17)
        //   r.data.detail.s17 = r.data.detail.s17.replaceAll(",", "、");
        // if (r.data.detail.s45)
        //   r.data.detail.s45 = r.data.detail.s45.replaceAll(",", "、");

        this.detail = r.data.detail;
        document.title = r.data.detail.s1;

        this.load = true;
        this.checkFav();
      }
    },
    p(str) {
      // 关键词高亮
      if (this.detail2.s45) {
        const words = this.detail2.s45.split(',')
        for (let word of words) {
          str = str.replaceAll(word, `<em>${word}</em>`)
        }
      }


      const arr = str.split("\n");
      let result = "";

      for (let item of arr) {
        item = "<p>" + item + "</p>";
        result += item;
      }

      return result;
    },

    print() {
      window.print()
    },

    async download() {
      const data = { ...this.detail2 };

      function toArr(str) {
        const r = []
        const arr = str.split('\n')
        for (let item of arr) {
          r.push({ value: item })
        }

        return r
      }

      // 当事人
      data.dangshirenArr = [];
      for (let item of data.dangshiren) {
        data.dangshirenArr.push({ value: item })
      }

      // 诉讼记录
      data.s23 = toArr(data.s23);
      // 审理过程
      data.s25 = toArr(data.s25);
      // 法庭观点
      data.s26 = toArr(data.s26);
      // 判决结果
      data.s27 = toArr(data.s27);
      // 审判人员
      data.s28 = toArr(data.s28);

      // 法律依据
      data.yijuArr = [];
      for (let item of data.s47) {
        data.yijuArr.push({ value: item })
      }

      function loadFile(url, callback) {
        PizZipUtils.getBinaryContent(url, callback);
      }

      loadFile("https://wenshu-api.ailvsifa.com/wenshuTpl.docx", function (
        error,
        content
      ) {
        if (error) {
          throw error;
        }
        const zip = new PizZip(content);
        const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true });
        doc.setData(data);
        try {
          // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
          doc.render();
        } catch (error) {
          // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
          function replaceErrors(key, value) {
            if (value instanceof Error) {
              return Object.getOwnPropertyNames(value).reduce(function (
                error,
                key
              ) {
                error[key] = value[key];
                return error;
              },
                {});
            }
            return value;
          }

          if (error.properties && error.properties.errors instanceof Array) {
            const errorMessages = error.properties.errors
              .map(function (error) {
                return error.properties.explanation;
              })
              .join("\n");
            console.log("errorMessages", errorMessages);
            // errorMessages is a humanly readable message looking like this :
            // 'The tag beginning with "foobar" is unopened'
          }
          throw error;
        }
        const out = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        });
        // Output the document using Data-URI
        saveAs(out, data.s1 + ".docx");
      });
    },
    async checkFav() {
      if (!this.$store.state.token) return;
      const r = await this.$axios.get('/checkFav?wid=' + this.id);
      console.log(r);
      if (r.status == 1) this.favStatus = r.e;
    },
    async fav(e) {
      const r = await this.$axios.post('/fav', {
        wid: this.id,
        status: e
      })

      if (r.status == 1) this.favStatus = e;
    }
  },
};
</script>