import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import store from './store/index'

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import { message } from 'ant-design-vue';
Vue.config.productionTip = false;
Vue.use(Antd);

import './assets/style.scss';

// ================ axios 配置 ================
// axios.defaults.baseURL = 'http://localhost:2001';
axios.defaults.baseURL = 'https://wenshu-api.ailvsifa.com';

let userInfo = localStorage.getItem('userInfo');
if(userInfo) {
  userInfo = JSON.parse(userInfo);
  store.commit('setUser', userInfo)
  console.log(userInfo, 112234);
}

axios.interceptors.request.use(function (config) {
  if(store.state.token) config.headers.token = store.state.token;
  return config;
}, function (error) { console.error(error, 111111111) });

axios.interceptors.response.use(function (response) {
  if (response.status == 200) {
    // 判断返回数据的状态 以便notification使用
    if (Object.prototype.toString.call(response.data) == '[object Object]') {
      
      switch (response.data.status) {
        case -100:
          response.data.s = 'error';
          router.push('/login');
          break;
        case -1:
          response.data.s = 'error'
          break;
        case 0:
          response.data.s = 'warning'
          break;
        case 1:
          response.data.s = 'success'
          break;
        case 2:
          response.data.s = 'info'
          break;
        default:
          response.data.s = 'info'
          break;
      }
      if(response.data.msg) message[response.data.s](response.data.msg)
    };
    return response.data
  }
  else {
    return response;
  }
}, function (error) { 
  console.error(error, '---------------服务端错误---------------')
  // router.push('/err')
  // message.error('服务端错误')
});

Vue.axios = Vue.prototype.$axios = axios;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
