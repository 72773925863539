<template>
  <div class="login-bg">
    <div class="login">
      <div class="login-container">
        <!-- <a-tabs v-model="type" style="margin-bottom: 20px">
          <a-tab-pane :key="1" tab="手机号注册"></a-tab-pane>
        </a-tabs> -->
        <div class="item">
          <a-input addon-before="+86" size="large" v-model="form.tel" placeholder="请输入您的手机号" />
        </div>
        <div class="item">
          <a-input-password size="large" v-model="form.password" placeholder="请输入6位以上复杂密码" />
        </div>

        <div class="item">
          <a-input-password size="large" v-model="form.password2" placeholder="请再次输入密码" />
        </div>
        <div class="item">
          <a-input-search size="large" v-model="form.code" placeholder="请输入短信验证码" @search="getCode">
            <a-button slot="enterButton" type="primary">
              <template v-if="status < 2">
                <a-icon v-if="status == 1" type="loading" />
                <span v-else>获取</span>
              </template>
              <template v-else>
                <a-icon type="check" />
                <!-- <span>重新获取</span> -->
              </template>
            </a-button>
          </a-input-search>
        </div>
        <div class="item">
          <a-checkbox v-model="form.agree">
            我已阅读并同意<span class="link">《使用条款》</span>
          </a-checkbox>
        </div>

        <div class="item">
          <a-button size="large" style="width: 100%" type="primary" @click="reg">提交注册</a-button>
        </div>
        <div class="item">
          <a-button size="large" type="link" @click="$router.push('/')">返回首页</a-button>
          <a-button size="large" style="float: right" type="link" @click="$router.push('/login')">前往登录</a-button>
        </div>
      </div>
    </div>
    <img src="../static/bottom.png" class="bottom" alt=""></img>
  </div>
</template>

<style lang="scss" scoped>
.login-bg{
  background: #fff;
  .bottom{
    width: 100%;
    margin-top: -120px;
    z-index: -100 !important;
  }
}
</style>

<script>
export default {
  data() {
    return {
      type: 1,
      status: 0,
      form: {
        tel: '',
        password: '',
        password2: '',
        code: '',
        agree: false
      }
    }
  },
  methods: {
    async getCode() {
      if (this.form.tel.length !== 11) return this.$message.error('请输入11位手机号码');

      this.status = 1;
      const r = await this.$axios.get('/sendCode?tel=' + this.form.tel);
      // this.$message[r.s](r.msg)
      this.status = 2;
    },
    async reg() {
      console.log(this.form);
      if (this.form.tel.length != 11) return this.$message.error('请输入11位手机号')
      if (!this.form.password) return this.$message.error('请输入登录密码')
      if (this.form.password.length < 6) return this.$message.error('请输入6位数以上复杂密码')
      if (this.form.password !== this.form.password2) return this.$message.error('两次输入的密码不一致')
      if (!this.form.code) return this.$message.error('请输入短信验证码')
      if (!this.form.agree) return this.$message.error('请阅读并勾选同意使用条款')

      const r = await this.$axios.post('/reg', this.form)

      this.status = 0;
      if (r.status == 1) {
        localStorage.setItem('userInfo', JSON.stringify(r.userInfo))
        this.$store.commit('setUser', r.userInfo);
        this.$router.push('/')
      }
    },
  }
}
</script>