<template>
  <div>
    <div class="container" style="max-width: 1200px">
      <div class="search">
        <a-select class="select" v-model="form.keywords_type">
          <a-select-option :value="1">标题</a-select-option>
          <a-select-option :value="2">全文</a-select-option>
        </a-select>
        <a-input class="main-input" type="text" v-model="form.keywords" placeholder="请输入案由、关键词、法院、当事人、律师等信息检索"
          @pressEnter="search">
          <a-icon slot="suffix" type="enter" style="color: #ccc; font-size: 20px" />
        </a-input>
        <button @click="search">检索</button>
      </div>
    </div>

    <div class="container2">
      <aside>
        <a-collapse v-model="activeKey">
          <a-collapse-panel key="1" header="精准检索">
            <div class="form-item">
              <b>空白文书</b>
              <div class="after">
                <a-radio-group v-model="form.blank">
                  <a-radio :value="1">忽略</a-radio>
                  <a-radio :value="0">不忽略</a-radio>
                </a-radio-group>
              </div>
            </div>

            <div class="form-item">
              <b>案件类型</b>
              <div class="after">
                <a-select v-model="form.type" @change="selectType" style="width: 100%">
                  <a-select-option v-for="(item, index) in typeList" :value="item.name" :key="index">{{ item.name }}
                  </a-select-option>
                </a-select>
              </div>
            </div>

            <div class="form-item">
              <b>案由</b>
              <div class="after">
                <a-cascader v-model="form.s11" change-on-select style="width: 100%"
                  :field-names="{ label: 'name', value: 'name', children: 'children' }" :options="anyouArr"
                  :show-search="{ filter }" placeholder="请选择或输入案由" />
              </div>
            </div>
            <div class="form-item">
              <b>诉讼记录</b>
              <div class="after">
                <a-input v-model="form.s23"></a-input>
              </div>
            </div>
            <div class="form-item">
              <b>事实</b>
              <div class="after">
                <a-input v-model="form.s25"></a-input>
              </div>
            </div>
            <div class="form-item">
              <b>法庭观点</b>
              <div class="after">
                <a-input v-model="form.s26"></a-input>
              </div>
            </div>
            <div class="form-item">
              <b>裁判结果</b>
              <div class="after">
                <a-input v-model="form.s27"></a-input>
              </div>
            </div>
            <div class="form-item">
              <b>关键词</b>
              <div class="after">
                <a-input v-model="form.s45"></a-input>
              </div>
            </div>
          </a-collapse-panel>

          <a-collapse-panel key="4" header="当事人">
            <div class="form-item">
              <b>当事人</b>
              <div class="after">
                <a-input v-model="form.s17"></a-input>
              </div>
            </div>
            <div class="form-item">
              <b>代理律所</b>
              <div class="after">
                <a-input v-model="form.lvsuo"></a-input>
              </div>
            </div>
            <div class="form-item">
              <b>代理律师</b>
              <div class="after">
                <a-input v-model="form.lvshi"></a-input>
              </div>
            </div>
          </a-collapse-panel>

          <a-collapse-panel key="2" header="审判程序">
            <a-tree v-model="form.s9" checkable :expanded-keys="expandedKeys" :auto-expand-parent="autoExpandParent"
              :selected-keys="selectedKeys" :tree-data="chengxuArr" @expand="onExpand" />
          </a-collapse-panel>

          <a-collapse-panel key="3" header="文书类型">
            <a-checkbox-group v-model="form.s6" class="after">
              <a-row>
                <a-col v-for="(item, index) in leixing" :key="index" :span="12">
                  <a-checkbox :value="item.code">
                    {{ item.name }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-collapse-panel>


          <a-collapse-panel key="5" header="审理法院">
            <div class="form-item">
              <b>法院层级</b>
              <a-checkbox-group v-model="form.cengji" class="after">
                <a-row>
                  <a-col :span="12">
                    <a-checkbox value="最高">
                      最高法院
                    </a-checkbox>
                  </a-col>
                  <a-col :span="12">
                    <a-checkbox value="高级">
                      高级法院
                    </a-checkbox>
                  </a-col>
                  <a-col :span="12">
                    <a-checkbox value="中级">
                      中级法院
                    </a-checkbox>
                  </a-col>
                  <a-col :span="12">
                    <a-checkbox value="基层">
                      基层法院
                    </a-checkbox>
                  </a-col>
                </a-row>
              </a-checkbox-group>
            </div>

            <div class="form-item">
              <b>审理法院</b>
              <div class="after">
                <a-input v-model="form.s2"></a-input>
              </div>
            </div>
            <div class="form-item">
              <b>审判人员</b>
              <div class="after">
                <a-input v-model="form.s28"></a-input>
              </div>
            </div>
          </a-collapse-panel>

          <a-collapse-panel key="6" header="裁判年份">
            <a-checkbox-group v-model="form.year">
              <a-row>
                <a-col v-for="(year, index) in yearList" :key="index" :span="12">
                  <a-checkbox :value="year">
                    {{ year == '2018' ? '2018及以前' : year + '年' }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-collapse-panel>

          <a-collapse-panel key="7" header="地域">
            <a-checkbox-group v-model="form.sheng">
              <a-row>
                <a-col v-for="(item, index) in diqu" :key="index" :span="12">
                  <a-checkbox :value="item">
                    {{ item }}
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-collapse-panel>

          <a-collapse-panel key="8" header="基本信息">
            <div class="form-item">
              <b>案号</b>
              <div class="after">
                <a-input v-model="form.s7"></a-input>
              </div>
            </div>
            <div class="form-item">
              <b>公开类型</b>
              <div class="after">
                <a-radio-group v-model="form.s43">
                  <a-radio value="00">
                    所有
                  </a-radio>
                  <a-radio value="01">
                    文书公开
                  </a-radio>
                  <a-radio value="02">
                    信息公开
                  </a-radio>
                </a-radio-group>
              </div>
            </div>
          </a-collapse-panel>
          <a-collapse-panel key="9" header="法律依据">
            <div class="form-item">
              <b>法律</b>
              <div class="after">
                <a-input v-model="form.falv"></a-input>
              </div>
            </div>
            <div class="form-item">
              <b>条款</b>
              <div class="after">
                <a-input v-model="form.tiaokuan"></a-input>
              </div>
            </div>
          </a-collapse-panel>
        </a-collapse>

        <div class="btns">
          <a-button @click="clearTags">清空</a-button>
          <a-button type="primary" @click="search">确定</a-button>
        </div>
      </aside>

      <section class="main">
        <!-- 检索条件 -->
        <div class="tags">
          <a-tag v-for="(tag, t) in tags" :key="t" style="margin-bottom: 5px;">
            {{ tag.title }}: {{ tag.showVal || tag.val }}
            <a-icon type="close" @click="delTag(tag, t)" />
          </a-tag>
          <a-button size="small" type="primary" v-if="tags.length" @click="clearTags()">重置条件
          </a-button>
        </div>

        <div class="loading" v-if="loading == 1" @click="loading = 0">
          <div class="tank">
            <a-spin style="margin-top: 20px" />
            <p>正在检索中</p>
          </div>
        </div>

        <div class="no-result" v-if="list.length == 0 && loading == 2">
          <a-icon type="info-circle" />
          <span>未检索到符合条件的结果！请修改检索条件再试</span>
        </div>

        <div v-if="total > 0" style="margin-left: 20px">检索到{{ total == 10000 ? '超过 ' + total : total }}条结果，耗时{{ second
        }}秒
        </div>

        <div class="list" id="l" style="padding: 10px 15px">
          <div v-for="(item, index) in list" :key="index" class="list-item">
            <div class="t">
              <div class="index">{{ index + 1 }}</div>
              <div class="word-num">正文字数：{{ item.word_num }} 字</div>
            </div>
            <router-link class="title" :to="'/detail?id=' + item.wid" target="_blank">
              <span class="b" v-html="item.s1"></span>
            </router-link>
            <div class="sub-title">
              <span>{{ item.s2 }}</span>
              <span>{{ item.s7 }}</span>
              <span>{{ item.s31 }}</span>
              <span>{{ item.prov }}</span>
            </div>
            <div class="info">
              <span style="margin-right: 20px; color: #555">
                <b>审判程序：</b>{{ item.s9 }}
              </span>
              <span style="color: #555"> <b>案由：</b>{{ item.s11 }} </span>
            </div>

            <div class="info" v-if="item.s45"><b>关键词：</b>{{ item.s45 }}</div>
          </div>
        </div>

        <div class="pagination" v-if="loading > 0 && list.length > 0">
          <a-pagination v-model="form.page" show-quick-jumper :default-current="1" :total="total" :pageSize="20"
            @change="search2" />
        </div>
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script>
export default {
  data() {
    return {
      form: {
        blank: 1, //忽略空白文书
        type: '', //案件类型
        keywords_type: 1, //大输入框的类型，关键词还是全文
        keywords: '', //多个字符串的数组，全文检索关键词
        s2: '', //审理法院
        s6: [], //文书类型 
        s7: '', //案号
        s9: [], //审判程序
        s11: [], //案由
        s17: '', //当事人
        lvshi: '', //律师
        lvsuo: '', //律所 字段对应lvshi
        s23: '', //诉讼记录
        s25: '', //事实
        s26: '', //理由
        s27: '', //裁判结果
        s28: '', //审判人员
        s43: '00', //公开类型
        s45: '', //关键字
        cengji: [], //法院层级
        year: [], //裁判年份 对应s31
        sheng: [], //地域 从s2审理法院中搜
        falv: '', //依据法律
        tiaokuan: '',//条款
        page: 1
      },
      tags: [],
      typeList: [{ code: "01", name: "管辖案件" },
      { code: "02", name: "刑事案件" },
      { code: "03", name: "民事案件" },
      { code: "04", name: "行政案件" },
      { code: "05", name: "国家赔偿与司法救助案件" },
      { code: "06", name: "区际司法协助案件" },
      { code: "07", name: "国际司法协助案件" },
      { code: "08", name: "司法制裁案件" },
      { code: "09", name: "非诉保全审查案件" },
      { code: "10", name: "执行案件" },
      { code: "11", name: "强制清算与破产案件" },
      { code: "99", name: "其他" },],
      activeKey: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
      yearList: ['2022', '2021', '2020', '2019', '2018'],

      // ----- 审判程序相关 -----
      chengxuArr: [], //审判程序
      expandedKeys: [],
      autoExpandParent: true,
      selectedKeys: [''],

      anyouArr: [], //案由列表
      diqu: [], //地区列表
      leixing: [], //文书类型

      list: [], //检索结果
      loading: 0,
      total: 0, //结果总数量
      second: 0, //耗时
    }
  },
  watch: {
    "$store.state.activeType"() {
      this.form.type = this.$store.state.activeType;
      this.getOptions();
      this.search()
    }
  },
  mounted() {
    this.form.type = this.$store.state.activeType;
    this.form.keywords = this.$route.query.keywords || '';
    if (this.$route.query.keywords_type) this.form.keywords_type = Number(this.$route.query.keywords_type);
    this.getOptions();
    this.genTags();
    this.search();
  },
  methods: {
    // ----------------------- tag相关 -----------------------
    // 生成tag
    genTags() {
      const tags = [];

      // 空白文书
      tags.push({ key: 'blank', type: 'str', title: '空白文书', val: this.form.blank == 1 ? '忽略' : '不忽略' });

      // 案件类型
      if (this.form.type) tags.push({ key: 'type', type: 'str', title: '案件类型', val: this.form.type });

      // 全文
      if (this.form.keywords) {
        let keywords = this.form.keywords.split(' ');
        for (let i = 0; i < keywords.length; i++) {
          tags.push({
            key: 'keywords',
            type: 'arr',
            title: '全文',
            index: i,
            val: keywords[i]
          })
        }
      }

      // 审理法院
      if (this.form.s2) tags.push({ key: 's2', type: 'str', title: '审理法院', val: this.form.s2 });

      // 文书类型
      if (this.form.s6) {
        const s6Map = new Map([
          ['01', '判决书'],
          ['02', '裁定书'],
          ['03', '调解书'],
          ['04', '决定书'],
          ['05', '通知书'],
          ['09', '令'],
          ['10', '其他']
        ])
        for (let i = 0; i < this.form.s6.length; i++) {
          tags.push({
            key: 's6',
            type: 'arr',
            title: '文书类型',
            index: i,
            val: this.form.s6[i],
            showVal: s6Map.get(this.form.s6[i])
          })
        }
      }

      // 案号
      if (this.form.s7) tags.push({ key: 's7', type: 'str', title: '案号', val: this.form.s7 });

      // 审判程序
      if (this.form.s9) {
        for (let i = 0; i < this.form.s9.length; i++) {
          tags.push({
            key: 's9',
            type: 'arr',
            title: '审判程序',
            index: i,
            val: this.form.s9[i],
          })
        }
      }

      // 案由
      if (this.form.s11.length) {
        let v = [...this.form.s11];
        const s11 = v.length > 0 ? v.reverse()[0] : '';
        tags.push({ key: 's11', type: 'str', title: '案由', val: s11 });
      }

      // 当事人
      if (this.form.s17) tags.push({ key: 's17', type: 'str', title: '当事人', val: this.form.s17 });

      // 律所
      if (this.form.lvsuo) tags.push({ key: 'lvsuo', type: 'str', title: '律所', val: this.form.lvsuo });

      // 律师
      if (this.form.lvshi) tags.push({ key: 'lvshi', type: 'str', title: '律师', val: this.form.lvshi });

      // 诉讼记录
      if (this.form.s23) tags.push({ key: 's23', type: 'str', title: '诉讼记录', val: this.form.s23 });

      // 事实
      if (this.form.s25) tags.push({ key: 's25', type: 'str', title: '事实', val: this.form.s25 });

      // 理由
      if (this.form.s26) tags.push({ key: 's26', type: 'str', title: '理由', val: this.form.s26 });

      // 裁判结果
      if (this.form.s27) tags.push({ key: 's27', type: 'str', title: '裁判结果', val: this.form.s27 });

      // 审判人员
      if (this.form.s28) tags.push({ key: 's28', type: 'str', title: '审判人员', val: this.form.s28 });

      // 关键词
      if (this.form.s45) tags.push({ key: 's45', type: 'str', title: '关键词', val: this.form.s45 });

      // 公开类型
      if (this.form.s43) {
        const s43Map = new Map([
          ['00', '所有'],
          ['01', '文书公开'],
          ['02', '信息公开']
        ])
        tags.push({ key: 's43', type: 'str', title: '公开类型', val: s43Map.get(this.form.s43) });
      }

      // 法院层级
      if (this.form.cengji) {
        for (let i = 0; i < this.form.cengji.length; i++) {
          tags.push({
            key: 'cengji',
            type: 'arr',
            title: '法院层级',
            index: i,
            val: this.form.cengji[i],
          })
        }
      }

      // 裁判年份
      if (this.form.year) {
        for (let i = 0; i < this.form.year.length; i++) {
          tags.push({
            key: 'year',
            type: 'arr',
            title: '裁判年份',
            index: i,
            val: this.form.year[i],
          })
        }
      }

      // 地域
      if (this.form.sheng) {
        for (let i = 0; i < this.form.sheng.length; i++) {
          tags.push({
            key: 'sheng',
            type: 'arr',
            title: '地域',
            index: i,
            val: this.form.sheng[i],
          })
        }
      }

      // 依据法律
      if (this.form.falv) tags.push({ key: 'falv', type: 'str', title: '依据法律', val: this.form.falv });

      // 条款
      if (this.form.tiaokuan) tags.push({ key: 'tiaokuan', type: 'str', title: '条款', val: this.form.tiaokuan });

      this.tags = tags;
    },
    // 删除tag
    delTag(item, index) {
      this.tags.splice(index, 1);

      // 如果是全文关键词
      if (item.key == 'keywords') {
        const arr = [];
        for (let item of this.tags) {
          if (item.key == 'keywords') {
            arr.push(item)
          }
        }

        const arr2 = [];
        for (let item of arr) {
          arr2.push(item.val)
        }

        this.form.keywords = arr2.join(' ')
      }
      else {
        if (item.type == 'arr') {
          const arr = [];
          for (let i of this.tags) {
            if (i.key == item.key) {
              arr.push(i.val)
            }
          }
          console.log(arr);
          this.form[item.key] = arr
        }
        else {
          this.form[item.key] = '';
        }
      }
      this.search()
    },
    clearTags() {
      this.total = 0;
      this.second = 0;

      this.tags = [];
      this.form = {
        blank: 1, //忽略空白文书
        type: this.$store.state.activeType, //案件类型
        keywords: '', //多个字符串的数组，全文检索关键词
        keywords_type: 1,
        s2: '', //审理法院
        s6: [], //文书类型 
        s7: '', //案号
        s9: [], //审判程序
        s11: [], //案由
        s17: '', //当事人
        lvshi: '', //律师
        lvsuo: '', //律所 字段对应lvshi
        s23: '', //诉讼记录
        s25: '', //事实
        s26: '', //理由
        s27: '', //裁判结果
        s28: '', //审判人员
        s43: '00', //公开类型
        cengji: [], //法院层级
        year: [], //裁判年份 对应s31
        sheng: [], //地域 从s2审理法院中搜
        falv: '', //依据法律
        tiaokuan: '',//条款
        page: 1
      }
      this.loading = 0;
      this.list = [];
      this.genTags();
      this.getOptions()
      // this.search()
    },

    selectType() {
      this.$store.commit('setType', this.form.type)
      this.getOptions();
    },

    // 获取案件配置选项
    async getOptions() {
      const r = await this.$axios.get('/getOptions?type=' + this.form.type)
      if (r.status == 1) {
        this.chengxuArr = r.chengxuArr;
        this.anyouArr = r.anyouArr;
        this.diqu = r.diqu;
        this.leixing = r.leixing;
      }
    },
    async search() {
      this.form.page = 1;
      const start = Date.now()
      this.loading = 1;
      this.genTags();

      await this.$nextTick()
      document.getElementById('app').scrollIntoView()

      try {
        const r = await this.$axios.post('/search', this.form);
        this.loading = 2;
        if (r.status == 1) {
          for (let item of r.list) {
            item.s45 = item.s45.replaceAll(',', '、')
          }
          this.list = r.list || []
          this.total = r.total || 0;
        }

        const end = Date.now()
        console.log('请求耗时：', end - start);
        this.second = Math.round((end - start) / 10) / 100;
      } catch (error) {
        this.$message.error('服务端连接错误')
      }
    },

    async search2() {
      const start = Date.now()
      this.loading = 1;
      this.genTags();

      await this.$nextTick()
      document.getElementById('app').scrollIntoView()
      try {
        const r = await this.$axios.post('/search', this.form);
        this.loading = 2;

        if (r.status == 1) {
          for (let item of r.list) {
            item.s45 = item.s45.replaceAll(',', '、')
          }
          this.list = r.list || []
          this.total = r.total || 0;
        }
      } catch (error) {
        this.$message.error('服务端连接错误')
      }

      const end = Date.now()
      console.log('请求耗时：', end - start);
      this.second = Math.round((end - start) / 10) / 100;
    },

    // ---------- 案由select ----------
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },

    // ---------- 案由级联选择 ----------
    filter(inputValue, path) {
      return path.some(option => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    },





    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onCheck(checkedKeys) {
      this.form.s9 = checkedKeys;
    }
  }
};
</script>