<template>
  <div class="vip">
    <div class="title">会员中心</div>
    <img v-if="userInfo.headimgurl" class="logo" :src="userInfo.headimgurl" alt="">
    <div class="tel">用户级别：{{userInfo.vip_type}}</div>
    <div class="tel">绑定微信：{{userInfo.nickname || '未绑定'}}</div>
    <div class="tel">登录账号：{{userInfo.tel}}</div>
    <div class="item">
      <div v-if="userInfo.vip == 1">
        <div v-if="!userInfo.end">到期时间：暂无</div>
        <div v-else>
          <div>到期时间：{{userInfo.end}}</div>
          <a-button style="margin-top: 20px" type="primary" @click="show_qr = true">续费会员</a-button>
          <a-button style="margin-top: 20px; margin-left: 20px" @click="show_qr2 = true">绑定微信</a-button>
        </div>
      </div>
      <div v-else>
        <a-button style="margin-top: 20px" type="primary" @click="show_qr = true">充值会员</a-button>

        <a-button style="margin-top: 20px; margin-left: 20px" @click="show_qr2 = true">绑定微信</a-button>
      </div>
    </div>

    <a-modal title="扫码购买会员" :visible="show_qr" @ok="show_qr = false" @cancel="show_qr = false">
      <img style="display: block; width: 200px;
    margin: 0 auto;" src="qr.jpg" alt="">
    </a-modal>

    <a-modal title="扫码绑定微信" :visible="show_qr2" @ok="show_qr2 = false" @cancel="show_qr2 = false">
      <img style="display: block; width: 200px;
    margin: 0 auto;" src="qr2.jpg" alt="">
      <p style="color: red; text-align: center">绑定微信后可扫码登录</p>
    </a-modal>
  </div>
</template>

<script>

export default {
  data() {
    return {
      userInfo: {},
      show_qr: false,
      show_qr2: false
    }
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    async getUserInfo() {
      const r = await this.$axios.get('/getUserInfo')
      if (r.status == 1) this.userInfo = r.user
    }
  }
}
</script>

<style>

</style>