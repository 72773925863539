<template>
  <div class="detail">
    <ul class="menu-list">
      <li v-for="(menu, m) in menuList" :key="m">
        <a @click="active_menu = menu.title" :href="'#' + menu.id" :class="{ active: active_menu == menu.title }">{{
            menu.title
        }}</a>
      </li>
    </ul>

    <section class="content">
      <div class="sec" id="reg">
        <div class="sec-title">账号注册</div>
        <div class="row">
          <div class="col">
            <p>本系统支持手机号注册，操作步骤</p>
            <ul>
              <li>输入手机号</li>
              <li>输入密码</li>
              <li>再次输入密码确认</li>
              <li>获取短信验证码</li>
              <li>输入4位短信验证码</li>
              <li>阅读并同意使用条款</li>
              <li>提交注册</li>
            </ul>
            <p>前往 <router-link to="/reg">注册页面</router-link>
            </p>
          </div>
          <div>
            <img width="480px" style="position: relative; top: -70px" src="../static/help/reg.png" alt="" />
          </div>
        </div>
      </div>
      
      <div class="sec" id="login">
        <div class="sec-title">用户登录</div>
        <p>本系统支持多种登录方式</p>
        <p>前往 <router-link to="/login">登录页面</router-link>
        <div class="row" style="margin-top: 60px">
          <div class="col">
            <h4>微信扫码登录</h4>
            <ul>
              <li>使用微信扫码，识别后点击允许即登录</li>
              <li><a href="https://oa.lvshizaixian.com.cn" target="_blank">爱律思法OA系统</a> 用户扫码可自动登录爱律思法账号</li>
              <li>没有账号的将会自动创建账号并登录</li>
            </ul>
          </div>
          <div>
            <img width="400px" src="../static/help/wechat.png" alt="" />
          </div>
        </div>

        <div class="row" style="margin-top: 60px">
          <div class="col">
            <h4>账号密码登录</h4>
            <ul>
              <li>可使用爱律思法OA系统账号密码登录</li>
              <li>也可使用此前注册的手机号和密码登录</li>
            </ul>
            <p>前往 <router-link to="/login">登录页面</router-link>
            </p>
          </div>
          <div>
            <img width="400px" src="../static/help/account.png" alt="">
          </div>
        </div>
      
        <div class="row" style="margin-top: 60px">
          <div class="col">
            <h4>短信验证码登录</h4>
            <ul>
              <li>忘记密码时可使用注册手机号+短信验证码方式登录</li>
            </ul>
            <p>前往 <router-link to="/login">登录页面</router-link>
            </p>
          </div>
          <div>
            <img width="400px" src="../static/help/code.png" alt="">
          </div>
        </div>

      </div>

      <div class="sec" id="search">
        <div class="sec-title">文书关键词检索</div>
        <div class="row" style="margin-top: 60px">
          <div class="col">
            <ul>
              <li>在首页搜索框输入关键词，多个关键词用空格隔开，点击提交即可获得检索结果</li>
            </ul>
          </div>
          <div>
            <img width="500px" src="../static/help/home.png" alt="" />
            <img width="500px" src="../static/help/list1.png" alt="" />
          </div>
        </div>
      </div>

      <div class="sec" id="search2">
        <div class="sec-title">精准检索</div>
        <div class="row" style="margin-top: 60px">
          <div class="col">
            <h4>精准检索</h4>
            <ul>
              <li>在检索结果页左侧的筛选表单中填写或选择相关条件，点击底部的确定按钮后检索出结果</li>
            </ul>
          </div>
          <div>
            <img width="500px" src="../static/help/list2.png" alt="" />
          </div>
        </div>
      </div>

      <div class="sec" id="read">
        <div class="sec-title">阅读文书详情</div>
        <div class="row" style="margin-top: 60px">
          <div class="col">
            <ul>
              <li>本系统将裁判文书自动分段，点击左侧目录可快速跳转至相应段落</li>
              <li>案件关键词自动高亮显示</li>
            </ul>
          </div>
          <div>
            <img width="500px" src="../static/help/read.png" alt="" />
          </div>
        </div>
      </div>

      <div class="sec" id="fav">
        <div class="sec-title">文书收藏</div>
        <div class="row" style="margin-top: 60px">
          <div class="col">
            <ul>
              <li>在文书详情页点击右侧心形图标即可收藏文书，收藏后按钮变成蓝色</li>
              <li>点击蓝色按钮会取消收藏</li>
              <li>仅限登录用户使用收藏功能</li>
              <li>点击右上角“收藏夹”进入收藏列表，如图示2</li>
            </ul>
          </div>
          <div>
            <img width="500px" src="../static/help/fav.png" alt="" />
            <img width="500px" src="../static/help/fav2.png" alt="" />
          </div>
        </div>
      </div>

      <div class="sec" id="print">
        <div class="sec-title">文书打印</div>
        <div class="row" style="margin-top: 60px">
          <div class="col">
            <ul>
              <li>在文书详情页点击右侧打印图标即可直接在线打印整篇文书</li>
            </ul>
          </div>
          <div>
            <img width="500px" src="../static/help/print.png" alt="" />
          </div>
        </div>
      </div>

      <div class="sec" id="download">
        <div class="sec-title">文书下载</div>
        <div class="row" style="margin-top: 60px">
          <div class="col">
            <ul>
              <li>在文书详情页点击右侧打印图标，可下载word版本文书</li>
            </ul>
          </div>
          <div>
            <img width="500px" src="../static/help/download.png" alt="" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
img {
  display: block;
  margin: 20px auto;
}
.sec{
  border-bottom: 1px solid #dedede;
}
.sec-title {
  left: 0 !important;
  text-align: left !important;
  padding: 0 !important;
}
</style>

<script>
export default {
  data() {
    return {
      menuList: [
        { id: "reg", title: "账号注册" },
        { id: "login", title: "用户登录" },
        { id: "search", title: "文书关键词检索" },
        { id: "search2", title: "文书精准检索" },
        { id: "read", title: "阅读文书详情" },
        { id: "fav", title: "文书收藏" },
        { id: "print", title: "文书打印" },
        { id: "download", title: "文书下载" },
      ],
      active_menu: "注册",
    };
  },
  created() {
  },
  methods: {

  },
};
</script>