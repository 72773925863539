<template>
  <footer class="footer">
    <div class="container">
      <ul class="link-list">
        <li v-for="(list, i) in list" :key="i">
          <a :href="list.path" target="_blank">{{ list.title }}</a>
        </li>
      </ul>
      <div class="copyright">© 爱律思法科技（深圳）有限公司</div>
      <a href="https://beian.miit.gov.cn/#/Integrated/index" class="icp" target="_blank">粤ICP备2020100756号-1</a>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: "爱律思法OA系统",
          path: "https://oa.lvshizaixian.com.cn/",
        },
        {
          title: "中国裁判文书网",
          path: "https://wenshu.court.gov.cn/",
        },
        {
          title: "中国执行信息公开网",
          path: "http://zxgk.court.gov.cn/",
        },
        {
          title: "国家法律法规数据库",
          path: "https://flk.npc.gov.cn/",
        },
        {
          title: "最高人民法院",
          path: "http://www.court.gov.cn/",
        },
      ],
    }
  }
};
</script>