import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: require('@/pages/index').default
  },
  {
    path: '/list',
    name: 'list',
    component: require('@/pages/list').default
  },
  {
    path: '/detail',
    name: 'detail',
    component: require('@/pages/detail').default
  },
  {
    path: '/favList',
    name: 'favList',
    component: require('@/pages/favList').default
  },
  {
    path: '/login',
    name: 'login',
    component: require('@/pages/login').default
  },
  {
    path: '/wechatLogin',
    name: 'wechatLogin',
    component: require('@/pages/wechatLogin').default
  },
  {
    path: '/reg',
    name: 'reg',
    component: require('@/pages/reg').default
  },
  {
    path: '/help',
    name: 'help',
    component: require('@/pages/help').default
  },
  {
    path: '/vip',
    name: 'vip',
    component: require('@/pages/vip').default
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// 避免跳转到当前网址的报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
