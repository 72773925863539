<template>
  <div style="margin-top: 20px">
    <div class="item">
      <a-input size="large" v-model="form.tel" placeholder="请输入手机号">
        <a-icon slot="prefix" type="mobile" />
      </a-input>
    </div>
    <div class="item">
      <a-input-search size="large" v-model="form.code" placeholder="请输入短信验证码" @search="getCode">
        <a-button slot="enterButton" type="primary">
          <template v-if="status < 2">
            <a-icon v-if="status == 1" type="loading" />
            <span v-else>获取</span>
          </template>
          <template v-else>
            <a-icon type="check" />
            <!-- <span>重新获取</span> -->
          </template>
        </a-button>
      </a-input-search>
    </div>
    <div class="item">
      <a-checkbox v-model="form.agree">
        我已阅读并同意<span class="link">《使用条款》</span>
      </a-checkbox>
    </div>
    <div class="item">
      <a-button size="large" style="width: 100%" type="primary" @click="login">提交登录</a-button>
    </div>
    <div class="item">
      <a-button size="large" type="link" @click="$router.push('/')">返回首页</a-button>
      <a-button size="large" style="float: right" type="link" @click="$router.push('/reg')">注册账号</a-button>
    </div>
  </div>
</template>

<style lang='scss' scord>
</style>

<script>
export default {
  data() {
    return {
      status: 0,
      form: {
        tel: '',
        code: '',
        agree: false
      }
    }
  },
  methods: {
    async getCode() {
      if (this.form.tel.length !== 11) return this.$message.error('请输入11位手机号码');

      this.status = 1;
      const r = await this.$axios.get('/sendCode?tel=' + this.form.tel);
      // this.$message[r.s](r.msg)
      this.status = 2;
    },
    async login() {
      console.log(this.form);
      if (!this.form.tel) return this.$message.error('请输入用户账号')
      if (!this.form.code) return this.$message.error('请输入验证码')
      if (!this.form.agree) return this.$message.error('请阅读并勾选同意使用条款')

      const r = await this.$axios.post('/codeLogin', this.form)
      if (r.status == 1) {
        localStorage.setItem('userInfo', JSON.stringify(r.userInfo))
        this.$store.commit('setUser', r.userInfo);
        this.$router.push('/')
      }
    },
  }
}
</script>